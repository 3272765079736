@font-face {
	font-family: 'Rubik';
	src: url('../fonts/RubikVariableFont.ttf') format('truetype'),
	url('../fonts/RubikVariableFont.woff') format('woff'),
	url('../fonts/RubikVariableFont.woff2') format('woff2'),
	url("../fonts/RubikVariableFont.eot") format("embedded-opentype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Medium.ttf') format('truetype'),
    url('../fonts/Rubik-Medium.woff') format('woff'),
    url('../fonts/Rubik-Medium.woff2') format('woff2'),
    url("../fonts/Rubik-Medium.eot") format("embedded-opentype");
    font-weight: 500;
    font-style: normal;
}