@import 'assets/css/_fonts.css';
@import 'assets/css/_reset.css';

:root {
    --up-white: #FFF;
    --up-black: #000;
    --up-main-blue: #3F7DF4;
    --up-main-dark-blue: #11368A;
    --up-main-blue-hover: #256FFE;
    --up-secondary-blue: #2768CC;
    --up-secondary--light-blue: #66ABF4;
    --up-main-red: #E05A5C;
    --up-main-dark: #1A1A36;
    --up-main-light-grey: #C9D1D9;
    --up-main-grey: #818E9C;
    --up-main-success: #00A6A9;
    --up-main-info: #3687EE;
    --up-main-orange: #F5B95F;
    --up-main-bg-modal: rgba(19, 16, 34, 0.6);
    --up-blue-p100: #F0F9FF;
    --up-blue-p200: #d3f1fd;
    --up-light-blue-p400: #AEDBFD;
    --up-container-box-shadow: rgba(42, 118, 207, 0.12);
    --up-page-bg: #ebf1f5;
    --main-box-shadow: 0 6px 6px 0 rgba(42, 118, 207, 0.12);
    --up-main-green: #4C8700;
    --up-main-light-green: #DDF0CE;
    --up-main-dark-red: #A12D43;
    --up-main-light-red: #FFC7BF;
    --up-third-light-blue: #B9D7F3;
    --up-main-grayish: #F8FBFC;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body * {
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1;
    color: var(--up-main-dark)
}
body * strong {
    font-weight: 500;
}
body * strong  *{
    font-weight: 500;
}
/*//custom scrollbar*/
body *::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: var(--up-page-bg);
}

body *::-webkit-scrollbar {
    width: 8px;
    background-color: var(--up-page-bg);
    border-radius: 8px;
}

body *::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: var(--up-light-blue-p400);
    border: 2px solid var(--up-page-bg);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}